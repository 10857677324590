import React from "react"
import { Row, Col } from "react-bootstrap"
import ReactMarkdown from "react-markdown"
import { AuthorWidget } from "./AuthorWidget"
export const InsightContent = ({ article }) => {
  function LinkRenderer(props) {
    return (
      <a href={props.href} target="_blank">
        {props.children}
      </a>
    )
  }
  return (
    <div className="position-relative">
      <Row className="mt-3">
        <Col lg={11} xs={12}>
          <div className="text-left post-content mb-5">
            <ReactMarkdown
              source={article.content}
              renderers={{ link: LinkRenderer }}
            />
          </div>
        </Col>
      </Row>
      <div className="mt-4">
        <hr />
        <Row>
          <Col lg={12} xs={12}>
            {article.author && <AuthorWidget author={article.author} />}
          </Col>
        </Row>
      </div>
    </div>
  )
}
