import { faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Row, Col, Image } from "react-bootstrap"
import tom_hunt from "../../images/stock/tom_hunt.jpeg"
import { ensurePublicURL } from "../general"

export const AuthorWidget = ({ author }) => {
  const headshot = ensurePublicURL(author.headshot)
  return (
    <div className="sidebar-widget mb-5">
      <h5 className="about-author text-left mt-3 pb-3">About The Author</h5>
      <div className="widget-content p-4 text-left">
        <Row>
          <Col lg={2}>
            <Image className="author-pic" thumbnail src={headshot.publicURL} />
          </Col>

          <Col lg={8}>
            <div className="flex-vertical-center">
              <h6 className="author-name">{author.name}</h6>
              <p className="bio-excerpt mt-3">{author.bio}</p>
            </div>
          </Col>
          <Col lg={2}>
            {author.linkedin_profile && (
              <div>
                <a
                  href={author.linkedin_profile}
                  target="_blank"
                  rel="noreferrer"
                  className="float-right pr-3"
                >
                  <FontAwesomeIcon size="3x" icon={faLinkedin} />
                </a>
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col lg={12}></Col>
        </Row>
      </div>
    </div>
  )
}
