import React from "react"
import { Col, Button, Form } from "react-bootstrap"
export const ReplyForm = () => {
  return (
    <div className="reply-container mt-5 mb-5">
      <h6 className="text-left">Leave a Reply</h6>
      <hr />
      <Form className="pt-4">
        <Form.Row className="pb-2">
          <Form.Group as={Col} controlId="formGridName">
            <Form.Control placeholder="Name" />
          </Form.Group>

          <Form.Group as={Col} controlId="formGridEmaila">
            <Form.Control type="email" placeholder="Email" />
          </Form.Group>
        </Form.Row>

        <Form.Group className="pb-2" controlId="fomrGridMessage">
          <Form.Control as="textarea" placeholder="Message" />
        </Form.Group>

        <div className="button-container">
          <Button variant="primary" type="submit">
            Send Message
          </Button>
        </div>
      </Form>
    </div>
  )
}
