import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/layout"
import { VerticalBar } from "../components/sitewide/VerticalBar"
import "../assets/css/theme/insights.scss"
import "../assets/css/theme/insights_detail.scss"
import { HoverDots } from "../components/sitewide/HoverDots"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faUser,
  faCalendar,
  faShareSquare,
} from "@fortawesome/free-solid-svg-icons"
import { Footer } from "../components/landing/_Footer"
import { graphql } from "gatsby"

import { ReplyForm } from "../components/insights/ReplyForm"
import { InsightContent } from "../components/insights/InsightContent"
import { ensurePublicURL } from "../components/general"

export const query = graphql`
  query ArticleQuery($id: Int!) {
    strapiArticles(strapiId: { eq: $id }) {
      strapiId
      title
      content
      excerpt
      categories {
        name
      }
      published_at
      image {
        publicURL
      }
      author {
        bio
        linkedin_profile
        name
        headshot {
          publicURL
        }
      }
    }
  }
`

const Article = ({ data }) => {
  const article = data.strapiArticles
  const backgroundImage = ensurePublicURL(article.image)

  const shareClick = () => {
    const url = typeof window !== "undefined" ? window.location.href : ""
    const shareUrl =
      "https://www.linkedin.com/shareArticle?mini=true&url=" +
      url +
      "&title=Gravitate&summary=&source="
    window.open(shareUrl, "_blank")
  }

  return (
    <Layout
      title={"Gravitate - " + article.title}
      description={article.excerpt}
      image={backgroundImage.publicURL}
    >
      <VerticalBar positionFixed hideConnect title={article.title} />
      <Container fluid className="p-0 outer-wrapper insights">
        <Row className="insight-header">
          <Col
            lg={12}
            xs={12}
            className="header-image-bg p-0"
            style={{ backgroundImage: `url(${backgroundImage.publicURL})` }}
          >
            <div className="blog-header-overlay" />
          </Col>
          <div className="blog-title">
            <h1 className="text-left">{article.title}</h1>
            <h4 className="text-left pt-3">{article.excerpt}</h4>
            <div className="flex-horizontal mt-4">
              <div>
                <FontAwesomeIcon icon={faUser} />
                <span className="post-meta">{article.author?.name}</span>
              </div>
              <div>
                <FontAwesomeIcon className="ml-2" icon={faCalendar} />
                <span className="post-meta">{article.published_at}</span>
              </div>
            </div>
          </div>
        </Row>
        <Row>
          <Col lg={4}></Col>
          <Col lg={8}>
            <Row className="share-row" style={{ height: 70 }}>
              <Col xs={2} className="share-click flex-vertical-center" lg={1}>
                <a
                  style={{ color: "var(--navy-site-bg)" }}
                  onClick={() => shareClick()}
                >
                  <FontAwesomeIcon icon={faShareSquare} />
                </a>
              </Col>
              <Col xs={10} className="share-text flex-vertical-center" lg={6}>
                <p> CLICK TO SHARE ON LINKED IN</p>
              </Col>
              <Col className="share-line mobile-hide" lg={5}></Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Container>
            <Row className="insight-content-container">
              <Col lg={12} xs={12}>
                <InsightContent article={article} />
                <ReplyForm />
              </Col>
            </Row>
          </Container>
        </Row>
        <Footer />
      </Container>
    </Layout>
  )
}
export default Article
